import React from 'react';
import './styleTable.css'
import axios from "axios";
import Arrow from "../img/arrow.svg";
import {Foot} from "./Foot";


class LeagueSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load1: false
        }

    }

    componentDidMount() {

    }

    selectLig = (LigaText) => {
        this.props.selectLig(LigaText);
        setTimeout(
            function () {
                this.props.history.push('/Table');
            }.bind(this),
            100
        );

    }

    backPost = () => {
        if (this.props.checkAd) {
            this.props.history.push('/');
        } else {
            this.props.history.push('/Admin');
        }
    }
    TablePush = () => {

        this.props.history.push('/leagueselection');

    }
    OnlinePush = () => {

        this.props.history.push('/Match');
    }
    NewsPush = () => {
        if (this.props.checkAd) {
            this.props.history.push('/');
        } else {
            this.props.history.push('/Admin');
        }
    }
    RankLink = () => {

        this.props.history.push('/rank');
    }

    render() {

        return (
            <div className="wrapperHomeG" style={{gridAutoRows: this.props.hIOS}}>
                <div className="wrapperTableParse">
                    <div className="headerTab" style={{backgroundImage: `url(${this.props.backImg})`}}>

                        <div className="HeaderTextComments1"><div>Лиги</div></div>
                    </div>
                    <Ligs selectLig={this.selectLig} colorApp={this.state.colorApp} LigsBase={this.props.LigsBase}/>
                </div>
                <Foot RankLink={this.RankLink} NewsPush={this.NewsPush} color1={this.props.colorNoActive} color2={this.props.colorApp} color3={this.props.colorNoActive} color4={this.props.colorNoActive} TablePush={this.TablePush} OnlinePush={this.OnlinePush}/>
            </div>
        );

    }


}

export default LeagueSelection;

const Liga = (props) => {
    return (
        <div onClick={props.selectLig.bind(null, props.LigaText)}
             className="selectLiga">
            <img src={props.countryImg}/>
            <div>{props.nameLiga}</div>
        </div>
    );
}

const Ligs = (props) => {


    let LigsBase = props.LigsBase.map((el) => <Liga
        LigaText={el.LigaText} id={el.idLiga}
        countryImg={el.country}
        nameLiga={el.nameLiga}
        selectLig={props.selectLig}/>)

        return <div className="postsBlockLiga">
        {LigsBase}
        <div style={{height: "5px"}}></div>
    </div>
}