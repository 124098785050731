import React from "react";
import './styleCommentsAdmin.css'
import '@vkontakte/vkui/dist/vkui.css';
import axios from "axios";
import './styleHomeAdmin.css'
import Arrow from "../../img/arrow.svg";
import {Avatar} from '@vkontakte/vkui';
import {confirmAlert} from 'react-confirm-alert';
import './styleConfirmAdmin.css';

class NewPosts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileLoaded: 0,
            checkload: true,
            uploadResult: "null",
        };
    }

    componentDidMount() {
        var self = this;
        axios.get(this.props.urlApp + 'infoAppBack.php', {}).then(function (response) {
            self.setState({
                backImg: response.data.headerImage,
                headerName: response.data.headerName,
                headerDescription: response.data.headerDescription
            });
        });
    }

    backPost = () => {
        this.props.history.push('/Admin');
    }

    //отправка мема на сервер
    sendNewPost = () => {
        var self = this;
        const data = new FormData();
        var header = document.getElementById('headerPost').value;
        var text = document.getElementById('textPost').value;
        data.append('file', this.state.selectedFile, this.state.selectedFile.name);
        data.append('text', text);
        data.append('header', header);
        data.append('key', this.props.uniqueAdminKey);
        data.append('vk_id', this.props.fetchedUser.id);
        data.append('urlApp', this.props.urlApp);
        axios.post( this.props.urlApp + 'addPostAppBack.php', data, {
            onUploadProgress: ProgressEvent => {
                this.setState({
                    fileLoaded: (ProgressEvent.loaded / ProgressEvent.total*100),
                })
            },
        }).then(function (response) {
            self.setState({
                uploadResult: response.data,
            });
        });


    }

    //функция выбора файла (onChange на type file)
    handleselectedFile = event => {
//берем имя файла
        var filename = document.getElementById("input-file").files;
        filename = filename[0]['name'];
        if(filename.length > 15){
            filename = filename.substring(0, 15) + '...';
        }
        this.setState({
            selectedFile: event.target.files[0],
            filename: filename,

        });
        setTimeout(
            function () {
                document.getElementById('loadImg').innerHTML="Загружено";
            }.bind(this),
            1000);

    }

//клик на нарисованный <input type="file" />
    clickToFile = () => {
        var file = document.querySelector('#input-file');
        file.click();

    }

    funAlert=()=>{
        if(this.state.checkload) {
            confirmAlert({
                title: 'Новый пост',
                message: 'Вы уверенны что заполнили все поля и загрузили изображение?',
                buttons: [
                    {
                        label: 'Да',
                        onClick: () => {
                            this.setState({checkload: false});
                            this.sendNewPost();
                        }
                    },
                    {
                        label: 'Нет',
                    }
                ]
            });
        }
        else
        {
            confirmAlert({
                title: 'Новый пост',
                message: 'Перезайдите во вкладку чтобы загрузить новый пост',
                buttons: [
                    {
                        label: 'Ок',
                    }
                ]
            });
        }
    }



    render() {
        return (
            <div className="wrapperComments" >
                <div className="headerComments" style={{backgroundImage: `url(${this.state.backImg})`}}>
                    <div onClick={this.backPost} className="HeaderArrowComments"><img className="imgArrowComments"
                                                                                      src={Arrow}></img></div>
                    <div className="HeaderTextComments">Новый пост</div>
                </div>
                <div className="bodyNewsPost">
                    <div className="PostTextHeaderAdd">Заголовок</div>
                    <textarea id="headerPost" className="PostTextHeaderAddInput"/>
                    <div className="PostTextAdd">Текст поста</div>
                    <textarea id="textPost" className="PostTextAddInput"></textarea>
                    <div className="loadimgHead">Загрузить изображение</div>
                    <div className="loadPostBut" onClick={this.clickToFile}> <div id="loadImg" className="loadPostTextBut">Загрузить</div>
                        <input type="file" style={{display: 'none'}} onChange={this.handleselectedFile} id="input-file" accept="image/*" />
                    </div>
                    <div onClick={this.funAlert} className="loadPostBut1"><div className="loadPostTextBut1">Подтвердить</div></div>
                    <div>Идет загрузка поста -> {this.state.fileLoaded}%</div>
                    <div>Результат -> {this.state.uploadResult}</div>
                </div>

            </div>
        );
    }
}

export default NewPosts;

