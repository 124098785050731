import React from 'react';
import './styleTable.css'
import axios from "axios";
import Arrow from "../img/arrow.svg";
import {Foot} from "./Foot";
import {Link} from "react-router-dom";


class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Table: "",
            HeaderTable: "",
        }

    }

    componentDidMount() {
        var self = this;

        axios.get(this.props.urlApp + 'parseTable.php', {
            params: {
                LigaText: this.props.LigaText
            }
        }).then(function (response) {
            self.setState({
                Table: response.data.Table
            });
        });



    }


    TablePush = () => {
        this.props.history.push('/leagueselection');
    }
    OnlinePush = () => {

        this.props.history.push('/Match');
    }
    NewsPush = () => {
        if (this.props.checkAd) {
            this.props.history.push('/');
        } else {
            this.props.history.push('/Admin');
        }
    }
    RankLink = () => {

        this.props.history.push('/rank');
    }

    render() {

        return (
            <div className="wrapperHomeG" style={{gridAutoRows: this.props.hIOS}}>
                <div className="wrapperTableParse">
                    <div className="headerTab" style={{backgroundImage: `url(${this.props.backImg})`}}>
                        <div className="HeaderTextComments1"><div>Таблица лиги</div></div>
                    </div>
                    <div className="wrapperTableBlock">
                    <div className="tableParse" dangerouslySetInnerHTML={{__html: this.state.Table}}>
                    </div>

                    </div>
               </div>

                <Foot hIOS={this.props.hIOS} RankLink={this.RankLink} NewsPush={this.NewsPush} color1={this.props.colorNoActive} color2={this.props.colorApp} color3={this.props.colorNoActive} color4={this.props.colorNoActive} TablePush={this.TablePush} OnlinePush={this.OnlinePush}/>
            </div>
        );

    }


}

export default Table;
