import React from 'react';
import {Avatar, Button, Div, Group, ListItem, Panel, PanelHeader} from '@vkontakte/vkui';
import './styleHomeAdmin.css'
import axios from "axios";
import {Post} from "./Post";
import connect from '@vkontakte/vkui-connect';
import {confirmAlert} from 'react-confirm-alert';
import './styleConfirmAdmin.css';
import {Foot} from "../Foot";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countPost: 2,
            readers: this.props.countUser,
            DataBasePost: this.props.DataBasePost,
            switchHead: true,
            DataBasePostHash: [],
            countPostHash: 2,
            textRead: "читателей",
            commentsApp: false,
            checkfunnLike: true,
            checkfunFixed: true,
            checkAd: true,
            loadInfo2: false,
        }

    }

    divideNumberByPieces = (x, delimiter) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter || " ");
    }

    componentDidMount() {

                if (this.props.userAd == 0) {
                    this.props.history.push('/');
                }


        let red = this.props.countUser;
        if (red % 100 == 11 || red % 100 == 12 || red % 100 == 13 || red % 100 == 14) {

        } else {
            if (red % 10 == 1) {
                this.setState({textRead: "читатель"})
            } else if (red % 10 == 2 || red % 10 == 3 || red % 10 == 4) {
                this.setState({textRead: "читателя"})
            }
        }


        var self = this;
        axios.get(this.props.urlApp + 'infoAppBack.php', {}).then(function (response) {
            self.setState({
                backImg: response.data.headerImage,
                headerName: response.data.headerName,
                headerDescription: response.data.headerDescription
            });
        });

        axios.get(this.props.urlApp + 'postAppBack.php', {
            params: {
                vk_id: this.props.fetchedUser.id,
                countPost: this.state.countPost,
                colorApp: this.props.colorApp
            }
        }).then(function (response) {
            self.setState({
                DataBasePost: response.data
            });
        });






    }

    headingSwitchNew = () => {
        var self = this;
        document.getElementById('postScroll').scrollTop = 0;
        if (!this.state.switchHead) {
            document.getElementById('colorNew').style.color = this.props.colorApp;
            document.getElementById('colorNewLine').style.backgroundColor = this.props.colorApp;
            document.getElementById('colorPopularLine').style.backgroundColor = "#C4CCE0";
            document.getElementById('colorPopular').style.color = "#3E3E3E";
            this.setState({switchHead: !this.state.switchHead});

            axios.get(this.props.urlApp + 'postAppBack.php', {
                params: {
                    vk_id: this.props.fetchedUser.id,
                    countPost: this.state.countPost,
                    colorApp: this.props.colorApp
                }
            }).then(function (response) {
                self.setState({
                    DataBasePost: response.data
                });
            });

        }

    }

    headingSwitchPopular = () => {
        var self = this;
        document.getElementById('postScroll').scrollTop = 0;
        if (this.state.switchHead) {
            document.getElementById('colorNew').style.color = "#3E3E3E";
            document.getElementById('colorNewLine').style.backgroundColor = "#C4CCE0";
            document.getElementById('colorPopularLine').style.backgroundColor = this.props.colorApp;
            document.getElementById('colorPopular').style.color = this.props.colorApp;
            this.setState({switchHead: !this.state.switchHead});
            axios.get(this.props.urlApp + 'postAppHashBack.php', {
                params: {
                    vk_id: this.props.fetchedUser.id,
                    countPostHash: this.state.countPostHash,
                    colorApp: this.props.colorApp
                }
            }).then(function (response) {
                self.setState({
                    DataBasePost: response.data
                });
            });
        }


    }

    funcScroll = () => {
        var s=document.getElementById('postScroll').scrollTop;
        var x = 1-(s/78);
        document.getElementById('read').style.opacity = x.toString();
        var self = this;
        if (+document.getElementById('postScroll').scrollTop + +70 > (document.getElementById('postScroll').scrollHeight - document.getElementById('postScroll').clientHeight)) {

            if (this.state.switchHead) {

                axios.get(this.props.urlApp + 'postAppBack.php', {
                    params: {
                        vk_id: this.props.fetchedUser.id,
                        countPost: this.state.countPost + 3,
                        colorApp: this.props.colorApp
                    }
                }).then(function (response) {
                    self.setState({
                        DataBasePost: response.data
                    });
                });
                this.setState({countPost: this.state.countPost + 3});
            } else {
                axios.get(this.props.urlApp + 'postAppHashBack.php', {
                    params: {
                        vk_id: this.props.fetchedUser.id,
                        countPostHash: this.state.countPostHash + 3,
                        colorApp: this.props.colorApp
                    }
                }).then(function (response) {
                    self.setState({
                        DataBasePost: response.data
                    });
                });
                this.setState({countPostHash: this.state.countPostHash + 3});
            }
        }

    }

    functionComments = (idPost, idmassiv) => {
        const list = this.state.DataBasePost; //клон mass array
        const item = list[idmassiv]; // mass[item] кликнутый итем
        const headerPost = item.postTitle;
        this.props.idComments(idPost, headerPost);
        this.props.history.push('/commentsAdmin');
    };


    functionLike = (idPost, idmassiv) => {
        var self = this;
        axios.get(this.props.urlApp + 'likeAppBack.php', {
            params: {
                vk_id: this.props.fetchedUser.id,
                idPost: idPost,
                colorApp: this.props.colorApp,
                rubric: this.state.switchHead,
                countHash: this.state.countPostHash,
                count: this.state.countPost,
                uniqalCodeUser: this.props.uniqalCodeUser
            }
        }).then(function (response) {
            console.log(response.data);
            self.setState({
                DataBasePost: response.data
            });
        });
    }


    repostFun = (idmassiv) => {
        const list = this.state.DataBasePost; //клон mass array
        const item = list[idmassiv];
        var text = item.postTitle + '\n\n' + item.postDescription + '\n\n' + "Присоединяйся к нам и будь в курсе всех футбольных новостей" + '\n' + "https://vk.com/app6966746";
        connect.send("VKWebAppShowWallPostBox", {
            "attachments": 'photo-181472933_456239018',
            "message": text,

        });
    }
    handleCheck = (idPost) => {

        confirmAlert({
            title: 'Закрепить/Открепить',
            message: 'Закрепить/Открепить пост?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => {
                        if (this.state.checkfunFixed) {
                            this.setState({checkfunFixed: false});
                            var self = this;
                            axios.get(this.props.urlApp + 'fixedPostAppBack.php', {
                                params: {
                                    vk_id: this.props.fetchedUser.id,
                                    idPost: idPost,
                                    uniqueAdminKey: this.props.uniqueAdminKey
                                }
                            });
                            setTimeout(
                                function () {
                                    this.refreshPost()
                                }.bind(this),
                                1000);

                        }
                    }
                },
                {
                    label: 'Нет',
                }
            ]
        });

    }
    refreshPost = () => {
        var self = this;
        if (!this.state.switchHead) {
            axios.get(this.props.urlApp + 'postAppHashBack.php', {
                params: {
                    vk_id: this.props.fetchedUser.id,
                    countPostHash: this.state.countPostHash,
                    colorApp: this.props.colorApp
                }
            }).then(function (response) {
                self.setState({
                    DataBasePost: response.data
                });
            });
        } else {
            axios.get(this.props.urlApp + 'postAppBack.php', {
                params: {
                    vk_id: this.props.fetchedUser.id,
                    countPost: this.state.countPost,
                    colorApp: this.props.colorApp
                }
            }).then(function (response) {
                self.setState({
                    DataBasePost: response.data
                });
            });
        }
        this.setState({checkfunFixed: true});
    }

    removePost = (idPost) => {
        confirmAlert({
            title: 'Удаление',
            message: 'Удалить пост',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => {
                        this.removePostB(idPost);
                    }
                },
                {
                    label: 'Нет',
                }
            ]
        });
    }
    removePostB = (idPost) => {
        axios.get(this.props.urlApp + 'removePostsAppBack.php', {
            params: {
                vk_id: this.props.fetchedUser.id,
                idPost: idPost,
                uniqueAdminKey: this.props.uniqueAdminKey
            }
        });
        setTimeout(
            function () {
                this.refreshPost();
            }.bind(this),
            1000);
    }
    addnewPost = () => {
        this.props.history.push('/newPostAdmin');
    }
    funAd = () => {
        var self = this;
        axios.get(this.props.urlApp + 'AdminCheck.php', {
            params: {
                loginUser: document.getElementById("input-login").value,
                passwordUser: document.getElementById("input-password").value,
                idUser: this.props.fetchedUser.id
            }
        }).then(function (response) {
            if (response.data) {
                self.props.fununiqueAdminKey(response.data.uniqueAdminKey, response.data.checkAd);
                self.setState({
                    checkAd: response.data.checkAd,
                    uniqueAdminKey: response.data.uniqueAdminKey,

                });
            }
        });

    }

    TuningAdmin = () => {
        this.props.history.push('/tuningAdmin');
    }

    TablePush = () => {
        this.props.history.push('/leagueselection');
    }
    OnlinePush = () => {

        this.props.history.push('/Match');
    }
    RankLink = () => {

        this.props.history.push('/rank');
    }


    render() {
        if (this.state.checkAd && this.props.checkAd) {
            return (
                <div className="wrapperAp">
                    <div className="loginApp">
                        <div className="loginAppDiv">Логин</div>
                        <input type="text" autoComplete="on" id="input-login" className="loginAppInput"/>
                    </div>
                    <div className="passwordApp">
                        <div className="loginAppDiv">Пароль</div>
                        <input type="password" autoComplete="on" id="input-password" className="loginAppInput"/>
                    </div>
                    <div onClick={this.funAd} className="butEnter">Вперед</div>

                </div>);
        } else {

            return (
                <div className="wrapperHomeG" style={{gridAutoRows: this.props.hIOS}}>
                    <div onScroll={this.funcScroll} id="postScroll" className="wrapperHome">
                        <div className="headerNameHome" style={{backgroundImage: `url(${this.state.backImg})`}}>
                            <div className="headerNameHome1">{this.state.headerName}</div>
                            <div className="headerDescriptionHome">{this.state.headerDescription}</div>
                        </div>

                        <div id="read" className="readerHeadline">{this.props.countUser} {this.state.textRead}</div>


                        <div className="headingSwitch">
                            <div onClick={this.headingSwitchNew} className="headingSwitchSeparation">
                                <div id="colorNew" style={{color: "#89C42F"}} className="headingSwitchNew">Новое
                                </div>
                                <div id="colorNewLine" style={{backgroundColor: this.props.colorApp}}
                                     className="headingSwitchNewHr"></div>
                            </div>
                            <div onClick={this.headingSwitchPopular} className="headingSwitchSeparation">
                                <div id="colorPopular" style={{color: "#3E3E3E"}}
                                     className="headingSwitchPopular">Популярное
                                </div>
                                <div id="colorPopularLine" style={{backgroundColor: "#C4CCE0"}}
                                     className="headingSwitchPopularHr"></div>
                            </div>
                        </div>
                        <div className="bodyHome">

                            <div className="headingHome">
                                <Post id="postF" colorApp={this.props.colorApp}
                                      functionLike={this.functionLike}
                                      DataBasePost={this.state.DataBasePost}
                                      repostFun={this.repostFun}
                                      removePost={this.removePost}
                                      functionComments1={this.functionComments}
                                      handleCheck={this.handleCheck}/>
                            </div>

                        </div>


                        <div onClick={this.addnewPost} className="LoadPost">
                            <div className="LoadPostPlus">+</div>

                        </div>

                        <div onClick={this.TuningAdmin} className="TunApp">
                            <div className="TunAppImg">*</div>
                        </div>

                    </div>
                    <Foot RankLink={this.RankLink} NewsPush={this.NewsPush} color1={this.props.colorNoActive} color2={this.props.colorNoActive} color3={this.props.colorNoActive} color4={this.props.colorApp} TablePush={this.TablePush} OnlinePush={this.OnlinePush}/>
                </div>
            );
        }

    }


}

export default Home;
