import React from 'react';
import './admin/styleHomeAdmin.css'
import axios from "axios";
import {Post} from "./Post";
import connect from '@vkontakte/vkui-connect';
import {Foot} from "./Foot";


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readers: this.props.countUser,
            DataBasePost: this.props.DataBasePost,
            switchHead: this.props.topRubric,
            DataBasePostHash: [],
            countPost: this.props.countPost,
            countPostHash: this.props.countPostHash,
            textRead: "читателей",
            commentsApp: false,
            checkfunnLike: true,
            stopScroll: true,

        }

    }

    divideNumberByPieces = (x, delimiter) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter || " ");
    }

    componentDidMount() {

        setTimeout(
            function () {
                if (this.props.userAd == 1) {
                    this.props.history.push('/Admin');
                }

            }.bind(this),
            1000
        );

        let red = this.props.countUser;
        if (red % 100 == 11 || red % 100 == 12 || red % 100 == 13 || red % 100 == 14) {

        } else {
            if (red % 10 == 1) {
                this.setState({textRead: "читатель"})
            } else if (red % 10 == 2 || red % 10 == 3 || red % 10 == 4) {
                this.setState({textRead: "читателя"})
            }
        }

        var self = this;

        if(!this.props.topRubric)
       {
            document.getElementById('postScroll').scrollTop = 0;
            document.getElementById('colorNew').style.color = "#3E3E3E";
            document.getElementById('colorNewLine').style.backgroundColor = "#C4CCE0";
            document.getElementById('colorPopularLine').style.backgroundColor = this.props.colorApp;
            document.getElementById('colorPopular').style.color = this.props.colorApp;
            this.setState({switchHead: false});
           axios.get(this.props.urlApp + 'postAppHashBack.php', {
               params: {
                   vk_id: this.props.fetchedUser.id,
                   countPostHash: this.state.countPostHash,
                   colorApp: this.props.colorApp
               }
           }).then(function (response) {

               self.setState({

                   DataBasePost: response.data
               });
           });
        }
        else{
            axios.get(this.props.urlApp + 'postAppBack.php', {
                params: {
                    vk_id: this.props.fetchedUser.id,
                    countPost: this.state.countPost,
                    colorApp: this.props.colorApp
                }
            }).then(function (response) {
                self.setState({
                    DataBasePost: response.data
                });
            });
        }
        document.getElementById('postScroll').scrollTop = this.props.topHome;




        document.getElementById('readers').innerHTML = this.divideNumberByPieces(this.state.readers);
        setTimeout(
            function () {
                if (this.props.openCheck) {

                    connect.subscribe((e) => {
                        switch (e.detail.type) {
                            case 'VKWebAppAllowNotificationsResult':
                                this.setState({isChecked: e.detail.data.result});
                                break;
                            default:
                                console.log(e.detail.type);
                        }
                    });
                    connect.send("VKWebAppAllowNotifications", {});
                }
            }.bind(this),
            1500
        );
    }

    headingSwitchNew = () => {
        var self = this;

        if (!this.state.switchHead) {
            document.getElementById('postScroll').scrollTop = 78;
            document.getElementById('colorNew').style.color = this.props.colorApp;
            document.getElementById('colorNewLine').style.backgroundColor = this.props.colorApp;
            document.getElementById('colorPopularLine').style.backgroundColor = "#C4CCE0";
            document.getElementById('colorPopular').style.color = "#3E3E3E";
            this.setState({switchHead: !this.state.switchHead});

            axios.get(this.props.urlApp + 'postAppBack.php', {
                params: {
                    vk_id: this.props.fetchedUser.id,
                    countPost: this.state.countPost,
                    colorApp: this.props.colorApp
                }
            }).then(function (response) {
                self.setState({
                    DataBasePost: response.data
                });
            });

        }
        else{
            var top=Math.max(document.getElementById('postScroll').scrollTop )+10;
            self.up(top);

        }

    }
    up=(top)=> {
        if(document.getElementById('postScroll').scrollTop!==null) {

            if (top > (document.getElementById('postScroll').scrollTop)) {
                var top = document.getElementById('postScroll').scrollTop;
                if (top > 0) {

                    document.getElementById('postScroll').scrollTop = document.getElementById('postScroll').scrollTop - 100;
                    var t = setTimeout(
                        function () {
                            if (document.getElementById('postScroll').scrollTop !== null) {
                                this.up(top);
                            }
                        }.bind(this),
                        15
                    );
                }
            }
        }
    }

    headingSwitchPopular = () => {
        var self = this;

        if (this.state.switchHead) {
            document.getElementById('postScroll').scrollTop = 78;
            document.getElementById('colorNew').style.color = "#3E3E3E";
            document.getElementById('colorNewLine').style.backgroundColor = "#C4CCE0";
            document.getElementById('colorPopularLine').style.backgroundColor = this.props.colorApp;
            document.getElementById('colorPopular').style.color = this.props.colorApp;
            this.setState({switchHead: !this.state.switchHead});
            axios.get(this.props.urlApp + 'postAppHashBack.php', {
                params: {
                    vk_id: this.props.fetchedUser.id,
                    countPostHash: this.state.countPostHash,
                    colorApp: this.props.colorApp
                }
            }).then(function (response) {

                self.setState({

                    DataBasePost: response.data
                });
            });
        }
        else{
            var top=Math.max(document.getElementById('postScroll').scrollTop )+10;
            self.up(top);

        }

    }

    funcScroll = () => {



            var s=document.getElementById('postScroll').scrollTop;
            var x = 1-(s/78);
            document.getElementById('read').style.opacity = x.toString();

        var self = this;
        if (+document.getElementById('postScroll').scrollTop + +70 > (document.getElementById('postScroll').scrollHeight - document.getElementById('postScroll').clientHeight)) {

            if (this.state.switchHead) {

                axios.get(this.props.urlApp + 'postAppBack.php', {
                    params: {
                        vk_id: this.props.fetchedUser.id,
                        countPost: this.state.countPost + 3,
                        colorApp: this.props.colorApp
                    }
                }).then(function (response) {
                    self.setState({
                        DataBasePost: response.data
                    });
                });
                this.setState({countPost: this.state.countPost + 3});
            } else {
                axios.get(this.props.urlApp + 'postAppHashBack.php', {
                    params: {
                        vk_id: this.props.fetchedUser.id,
                        countPostHash: this.state.countPostHash + 3,
                        colorApp: this.props.colorApp
                    }
                }).then(function (response) {
                    self.setState({
                        DataBasePost: response.data
                    });
                });
                this.setState({countPostHash: this.state.countPostHash + 3});
            }
        }

    }

    functionComments = (idPost, idmassiv) => {
        const list = this.state.DataBasePost; //клон mass array
        const item = list[idmassiv]; // mass[item] кликнутый итем
        const headerPost = item.postTitle;
        this.props.idComments(idPost, headerPost);
        this.props.saveTopState(this.state.DataBasePost,this.state.countPostHash, this.state.countPost,this.state.switchHead,document.getElementById('postScroll').scrollTop);

        this.props.history.push('/comments');
    };


    functionLike = (idPost, idmassiv) => {

            var self = this;
            axios.get(this.props.urlApp + 'likeAppBack.php', {
                params: {
                    vk_id: this.props.fetchedUser.id,
                    idPost: idPost,
                    colorApp: this.props.colorApp,
                    rubric: this.state.switchHead,
                    countHash: this.state.countPostHash,
                    count: this.state.countPost,

                }
            }).then(function (response) {
                console.log(response.data);
                self.setState({
                    DataBasePost: response.data
                });
            });


    }


    repostFun = (idmassiv) => {
        const list = this.state.DataBasePost; //клон mass array
        const item = list[idmassiv];
        var text = item.postTitle + '\n\n' + item.postDescription + '\n\n' + "Присоединяйся к нам и будь в курсе всех футбольных новостей" + '\n' + "https://vk.com/app6966746";
        connect.send("VKWebAppShowWallPostBox", {
            "attachments": 'photo-181472933_456239018',
            "message": text,

        });
        this.props.saveTopState(this.state.DataBasePost,this.state.countPostHash, this.state.countPost,this.state.switchHead,document.getElementById('postScroll').scrollTop);

    }

    TablePush = () => {
        this.props.saveTopState(this.state.DataBasePost,this.state.countPostHash, this.state.countPost,this.state.switchHead,document.getElementById('postScroll').scrollTop);

        this.props.history.push('/leagueselection');
    }
    OnlinePush = () => {
        this.props.saveTopState(this.state.DataBasePost,this.state.countPostHash, this.state.countPost,this.state.switchHead,document.getElementById('postScroll').scrollTop);

        this.props.history.push('/Match');
    }
    NewsPush = () => {
        var top=Math.max(document.getElementById('postScroll').scrollTop )+10;
        this.up(top);
    }
    RankLink = () => {
        this.props.saveTopState(this.state.DataBasePost,this.state.countPostHash, this.state.countPost,this.state.switchHead,document.getElementById('postScroll').scrollTop);

        this.props.history.push('/rank');

    }

    render() {

        return (
            <div className="wrapperHomeG" style={{gridAutoRows: this.props.hIOS}}>
                <div onScroll={this.funcScroll} id="postScroll" className="wrapperHome">


                    <div className="headerNameHome" style={{backgroundImage: `url(${this.props.backImg})`,backgroundColor: this.props.colorApp}}>
                        <div className="headerNameHome1">{this.props.headerName}</div>
                        <div className="headerDescriptionHome">{this.props.headerDescription}</div>
                    </div>

                    <div id="read" className="readerHeadline"><span id="readers"></span> {this.state.textRead}</div>

                    <div className="headingSwitch">
                        <div onClick={this.headingSwitchNew} className="headingSwitchSeparation">
                            <div id="colorNew" style={{color: "#89C42F"}} className="headingSwitchNew">Новое
                            </div>
                            <div id="colorNewLine" style={{backgroundColor: this.props.colorApp}}
                                 className="headingSwitchNewHr"></div>
                        </div>
                        <div onClick={this.headingSwitchPopular} className="headingSwitchSeparation">
                            <div id="colorPopular" style={{color: "#3E3E3E"}}
                                 className="headingSwitchPopular">Популярное
                            </div>
                            <div id="colorPopularLine" style={{backgroundColor: "#C4CCE0"}}
                                 className="headingSwitchPopularHr"></div>
                        </div>
                    </div>

                    <div className="bodyHome">

                        <div className="headingHome">

                            <Post id="postF" colorApp={this.props.colorApp}
                                  functionLike={this.functionLike}
                                  DataBasePost={this.state.DataBasePost}
                                  repostFun={this.repostFun}
                                  functionComments1={this.functionComments}/>

                        </div>

                    </div>
                </div>
               <Foot RankLink={this.RankLink} NewsPush={this.NewsPush} color1={this.props.colorNoActive} color2={this.props.colorNoActive} color3={this.props.colorNoActive} color4={this.props.colorApp} TablePush={this.TablePush} OnlinePush={this.OnlinePush}/>

            </div>
        );

    }


}

export default Home;

