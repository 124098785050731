import React from 'react';
import connect from '@vkontakte/vkui-connect';
import '@vkontakte/vkui/dist/vkui.css';
import axios from 'axios';
import Home from './panels/Home';
import Comments from './panels/Comments';
import HomeAdmin from './panels/admin/Home';
import CommentsHomeAdmin from './panels/admin/Comments';
import NewPosts from './panels/admin/newPost';
import {Route, Switch} from "react-router-dom";
import "./styleApp.css"
import TuningAdmin from "./panels/admin/TuningAdmin";
import Table from "./panels/Table";
import LeagueSelection from "./panels/LeagueSelection";
import Match from "./panels/Match"
import Rank from "./panels/Rank";
import "./circle.css"



class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchedUser: null,
            backImg: null,
            headerName: 0,
            headerDescription: 0,
            loadVk: false,
            loadInfo: false,
            loadInfo1: false,
            loadInfo2: false,
            load1: false,
            load2: false,
            countUser: 0,
            idPostComments: null,
            uniqueAdminKey: null,
            checkAd: true,
            urlApp: "https://Rabona.irsib.pro/backApp/",
            LigaText: "Англия",
            colorNoActive: "#C4CCE0",

            hIOSComnents: "calc(100% - 10%) 10% ",
            wrapH: "70px 50px auto 10px",
            colorApp: "#89C42F",
            topHome: 0,
            topRubric: true,
            countPost: 3,
            countPostHash: 3,
            DataBasePost: [],
        };
    }

    vkData = () => {
        var self = this;
        connect.subscribe((e) => {
            switch (e.detail.type) {
                case 'VKWebAppGetUserInfoResult':
                    this.setState({fetchedUser: e.detail.data, loadVk: true});
                    break;
                default:
                    console.log(e.detail.type);
            }


            axios.get(this.state.urlApp + 'userOpenAppBack.php', {
                params: {
                    vkid: e.detail.data.id,
                    firstName: e.detail.data.first_name,
                    lastName: e.detail.data.last_name,
                    photoAva: e.detail.data.photo_100
                }
            }).then(function (response) {
               
                self.setState({
                    userAd: response.data.adminApp,
                    openCheck: response.data.openCheck,
                    openCheckFallow: response.data.openCheckFallow,

                    loadInfo2: true
                });
            });

            axios.get(this.state.urlApp + 'postAppBack.php', {
                params: {
                    vk_id: e.detail.data.id,
                    countPost: 3,
                    colorApp: self.state.colorApp,
                }
            }).then(function (response) {
                self.setState({
                    DataBasePost: response.data,
                    load2: true
                });
            });
        });
        connect.send('VKWebAppGetUserInfo', {});

    }

    idComments = (idPost,headerPost) => {
        this.setState({idPostComments: idPost, headerPost:headerPost});

    }

    infoAppref=()=>{
        var self = this;
        axios.get(this.state.urlApp + 'infoAppBack.php', {}).then(function (response) {
            self.setState({
                backImg: response.data.image,
                colorApp: response.data.color,
                loadInfo: true
            });

        });
    }

    componentDidMount() {



        var self = this;
        axios.get(this.state.urlApp + 'userCountAppBack.php', {}).then(function (response) {
            self.setState({
                countUser: response.data.countS,
                loadInfo1: true
            });
        });

        axios.get(this.state.urlApp + 'infoAppBack.php', {}).then(function (response) {
            self.setState({
                backImg: response.data.headerImage,
                headerName: response.data.headerName,
                headerDescription: response.data.headerDescription,
                colorApp: response.data.color,
                loadInfo: true
            });

        });


        this.vkData();

        axios.get(this.state.urlApp + 'LeagueSelectionAppBack.php', {}).then(function (response) {
            self.setState({
                LigsBase: response.data,
                load1: true
            });
        });


    }
    fununiqueAdminKey=(uniqueAdminKey1,checkAd)=>
    {
        this.setState({uniqueAdminKey:uniqueAdminKey1, checkAd:checkAd});
    }

    selectLig=(LigaText)=>{
        this.setState({LigaText: LigaText});
    }

    saveTopState=(DataBasePost,countPostHash,countPost,topRubric,topHome)=>{

        this.setState({
            DataBasePost: DataBasePost,
            countPostHash: countPostHash,
            countPost: countPost,
            topRubric: topRubric,
            topHome: topHome,

        });
    }



    render() {
        if (!this.state.load2 || !this.state.load1 || !this.state.loadVk || !this.state.loadInfo || !this.state.loadInfo1 || !this.state.loadInfo2) {
            return <div className="spinnerDiv">

            <svg className="spinner" width="150px" height="150px" viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33"
                        r="30"> </circle>
            </svg>
            </div>
        }

            return (
                <div className="wrapApp">
                <Switch location={this.props.location}>
                    <Route exact path="/" render={props => <Home {...props} idComments={this.idComments}
                                                                 backImg={this.state.backImg}
                                                                 headerName={this.state.headerName}
                                                                 countPostHash={this.state.countPostHash}
                                                                 countPost={this.state.countPost}
                                                                 saveTopState={this.saveTopState}
                                                                 headerDescription={this.state.headerDescription}
                                                                 topHome={this.state.topHome}
                                                                 topRubric={this.state.topRubric}
                                                                 DataBasePost={this.state.DataBasePost}
                                                                 countUser={this.state.countUser}
                                                                 colorApp={this.state.colorApp}
                                                                 fetchedUser={this.state.fetchedUser}
                                                                 userAd={this.state.userAd}
                                                                 urlApp={this.state.urlApp}
                                                                 openCheck={this.state.openCheck}
                                                                 colorNoActive={this.state.colorNoActive}

                                                                 openCheckFallow={this.state.openCheckFallow}


                    />}/>
                    <Route exact path="/comments" render={props => <Comments {...props}

                                                                             idPostComments={this.state.idPostComments}
                                                                             colorApp={this.state.colorApp}
                                                                             fetchedUser={this.state.fetchedUser}
                                                                             headerPost={this.state.headerPost}
                                                                             urlApp={this.state.urlApp}
                                                                             colorNoActive={this.state.colorNoActive}
                                                                             hIOSComnents={this.state.hIOSComnents}

                    />}/>
                    <Route exact path="/Admin" render={props => <HomeAdmin {...props} idComments={this.idComments}
                                                                      uniqueAdminKey={this.state.uniqueAdminKey}
                                                                      countUser={this.state.countUser}
                                                                      colorApp={this.state.colorApp}
                                                                      fetchedUser={this.state.fetchedUser}
                                                                      fununiqueAdminKey={this.fununiqueAdminKey}
                                                                           checkAd={this.state.checkAd}
                                                                           colorNoActive={this.state.colorNoActive}
                                                                           urlApp={this.state.urlApp}

                                                                           userAd={this.state.userAd}
                                                                           DataBasePost={this.state.DataBasePost}


                    />}/>
                    <Route exact path="/commentsAdmin" render={props => <CommentsHomeAdmin {...props}
                                                                                           hIOSComnents={this.state.hIOSComnents}
                                                                                      idPostComments={this.state.idPostComments}
                                                                                      colorApp={this.state.colorApp}
                                                                                      fetchedUser={this.state.fetchedUser}
                                                                                      headerPost={this.state.headerPost}
                                                                                      uniqueAdminKey={this.state.uniqueAdminKey}
                                                                                           urlApp={this.state.urlApp}
                                                                                           colorNoActive={this.state.colorNoActive}



                    />}/>
                    <Route exact path="/newPostAdmin" render={props => <NewPosts {...props}
                                                                            colorApp={this.state.colorApp}
                                                                            fetchedUser={this.state.fetchedUser}
                                                                            uniqueAdminKey={this.state.uniqueAdminKey}
                                                                                 urlApp={this.state.urlApp}
                                                                                 colorNoActive={this.state.colorNoActive}

                    />}/>
                    <Route exact path="/tuningAdmin" render={props => <TuningAdmin {...props}
                                                                                   infoAppref={this.infoAppref}
                                                                                 fetchedUser={this.state.fetchedUser}
                                                                                 uniqueAdminKey={this.state.uniqueAdminKey}
                                                                                   urlApp={this.state.urlApp}
                                                                                   colorNoActive={this.state.colorNoActive}

                    />}/>
                    <Route exact path="/table" render={props => <Table {...props}
                                                                       checkAd={this.state.checkAd}
                                                                                   fetchedUser={this.state.fetchedUser}
                                                                                   urlApp={this.state.urlApp}
                                                                                   colorApp={this.state.colorApp}
                                                                       backImg={this.state.backImg}
                                                                       LigaText={this.state.LigaText}
                                                                       colorNoActive={this.state.colorNoActive}


                    />}/>
                    <Route exact path="/leagueselection"  render={props => <LeagueSelection {...props}
                                                                                            selectLig={this.selectLig}
                                                                       fetchedUser={this.state.fetchedUser}

                                                                       urlApp={this.state.urlApp}
                                                                       colorApp={this.state.colorApp}
                                                                       backImg={this.state.backImg}
                                                                                            LigsBase={this.state.LigsBase}
                                                                                            colorNoActive={this.state.colorNoActive}
                                                                                            checkAd={this.state.checkAd}


                    />}/>
                    <Route exact path="/Match"  render={props => <Match {...props}
                                                                                            fetchedUser={this.state.fetchedUser}
                                                                                            urlApp={this.state.urlApp}
                                                                                            colorApp={this.state.colorApp}
                                                                                            backImg={this.state.backImg}
                                                                                            checkAd={this.state.checkAd}
                                                                        colorNoActive={this.state.colorNoActive}

                    />}/>
                    <Route exact path="/rank"  render={props => <Rank {...props}
                                                                        fetchedUser={this.state.fetchedUser}
                                                                        urlApp={this.state.urlApp}
                                                                        colorApp={this.state.colorApp}
                                                                        backImg={this.state.backImg}
                                                                        checkAd={this.state.checkAd}
                                                                        colorNoActive={this.state.colorNoActive}

                    />}/>

                </Switch>
                    <div id="wrapApp1"> </div>
                </div>
            );



    }



}

export default App;
