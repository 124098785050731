import React from "react";
import './admin/styleCommentsAdmin.css'
import '@vkontakte/vkui/dist/vkui.css';
import axios from "axios";
import './admin/styleHomeAdmin.css'
import Arrow from "../img/arrow.svg";
import {Avatar} from '@vkontakte/vkui';
import {CommentPost} from "./CommentPost";
import { confirmAlert } from 'react-confirm-alert';
import './admin/styleConfirmAdmin.css';
import {Foot} from "./Foot";

class Comments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idPostComments: this.props.idPostComments,
            DataBaseComments: [],

        };
    }
    componentDidMount() {
        var self = this;
        axios.get(this.props.urlApp + 'infoAppBack.php', {}).then(function (response) {
            self.setState({
                backImg: response.data.headerImage,
                headerName: response.data.headerName,
                headerDescription: response.data.headerDescription
            });
        });
        axios.get(this.props.urlApp + 'commentsAppBack.php', {
            params: {
                idComment: this.props.idPostComments,
                idUser: this.props.fetchedUser.id
            }
        }).then(function (response) {
            if (response.data) {
                self.setState({
                    DataBaseComments: response.data
                });
            }
        });

        this.timerId = setInterval(() => this.refreshComments(), 10000);
    }

    refreshComments=()=>{
        var self = this;
        axios.get(this.props.urlApp + 'commentsAppBack.php', {
            params: {
                idComment: this.props.idPostComments,
                idUser: this.props.fetchedUser.id
            }
        }).then(function (response) {
            if (response.data) {
                self.setState({
                    DataBaseComments: response.data
                });
            }
            else
            {
                self.setState({
                DataBaseComments: []
                });
            }
        });
    }

    backPost=()=>{
        this.props.history.push('/');
    }

    addComments=()=>{
        var str =document.getElementById("input-id").value;
        str=str.replace(/^\s*/,'').replace(/\s*$/,'');

        if(str!="" && str.length <=250) {
            if(this.props.idPostComments) {
                axios.get(this.props.urlApp + 'addCommentsAppBack.php', {
                    params: {
                        commentPost: document.getElementById("input-id").value,
                        idComment: this.props.idPostComments,
                        vkid: this.props.fetchedUser.id,
                    }
                });

                setTimeout(
                    function () {
                        this.refreshComments();
                    }.bind(this),
                    300);
                setTimeout(
                    function () {
                        document.getElementById('postScroll1').scrollTop = document.getElementById('postScroll1').scrollHeight - document.getElementById('postScroll1').clientHeight;
                    }.bind(this),
                    500);
            }

            document.getElementById("input-id").value = "";
        }
        else{
            confirmAlert({
                title: 'Ошибка',
                message: 'Комментарий не может быть пустым или длиннее 250 символов',
                buttons: [
                    {
                        label: 'Ок',
                    }
                ]
            });
        }
    }
    removeComments=(commentId)=>{
        confirmAlert({
            title: 'Удаление',
            message: 'Удалить комментарий?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => {this.removeCom(commentId);}
                },
                {
                    label: 'Нет',
                }
            ]
        });
    }
    removeCom=(commentId)=>{
        axios.get(this.props.urlApp + 'removeCommentsAppBack.php', {
            params: {
                idComment: commentId,
                vkid: this.props.fetchedUser.id,
            }
        });
        setTimeout(
            function () {
                this.refreshComments();
            }.bind(this),
            500);
    }
    TablePush = () => {
        this.props.history.push('/leagueselection');
    }
    OnlinePush = () => {

        this.props.history.push('/Match');
    }
    NewsPush = () => {

        this.props.history.push('/');
    }

   Funlike=(commentId)=>{
       var r=document.getElementById('postScroll1').scrollTop;
       var self = this;
       const data = new FormData();
       data.append('idComments', commentId);
       data.append('like', 1);
       data.append('vk_id', this.props.fetchedUser.id);
       data.append('idPost', this.props.idPostComments);


       axios.post( this.props.urlApp + 'likeComments.php', data, {

       }).then(function (response) {
           self.setState({
               DataBaseComments: response.data
           });
           document.getElementById('postScroll1').scrollTop=r;
       });

   }
    Dislike=(commentId)=>{
        var r=document.getElementById('postScroll1').scrollTop;
                var self = this;
        const data = new FormData();
        data.append('idComments', commentId);
        data.append('like', -1);
        data.append('vk_id', this.props.fetchedUser.id);
        data.append('idPost', this.props.idPostComments);

        axios.post( this.props.urlApp + 'likeComments.php', data, {

        }).then(function (response) {
            self.setState({
                DataBaseComments: response.data
            });
            document.getElementById('postScroll1').scrollTop=r;
        });


    }
    RankLink = () => {

        this.props.history.push('/rank');
    }


    render() {
        return (
            <div className="wrapperCommentsG" style={{gridAutoRows: this.props.hIOSComnents}}>
            <div className="wrapperComments">
                <div className="headerComments" style={{backgroundImage: `url(${this.state.backImg})`}}>
                <div onClick={this.backPost} className="HeaderArrowComments"><img className="imgArrowComments" src={Arrow}></img></div>
                    <div className="HeaderTextComments"><div>Комментарии</div></div>
            </div>
                <div className="mainComments">
                    <div className="bodyComments">
                        <div className="namePost">{this.props.headerPost}</div>
                        <CommentPost Dislike={this.Dislike} Funlike={this.Funlike} removeComments={this.removeComments} DataBaseComments={this.state.DataBaseComments}/>
                    </div>
                    <div className="footerComments">
                        <textarea placeholder="Ваш комментарий" onFocus={()=>{if (document.getElementById("input-id").placeholder == 'Ваш комментарий') {document.getElementById("input-id").placeholder = ''}}} onBlur={()=>{if (document.getElementById("input-id").placeholder == '') {document.getElementById("input-id").placeholder = 'Ваш комментарий'}}}  id="input-id" className="butComments"/>
                        <div onClick={this.addComments} className="buttonComments">
                            <svg width="26" height="28" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 11.077L13.6664 6.3072L12.9929 7.76747L18.4233 10.2733H4.2257L2.37726 2.86589L5.69561 4.39929L6.36909 2.93982L0 0L2.76945 11.077L0 22.154L24 11.077ZM4.22972 11.8807H18.4233L2.37404 19.2881L4.22972 11.8807Z" fill={this.props.colorApp}/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
                <Foot RankLink={this.RankLink}  NewsPush={this.NewsPush} color1={this.props.colorNoActive} color2={this.props.colorNoActive} color3={this.props.colorNoActive} color4={this.props.colorNoActive} TablePush={this.TablePush} OnlinePush={this.OnlinePush}/>
            </div>
        );
    }
}

export default Comments;

