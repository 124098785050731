import React from "react";
import './admin/styleCommentsAdmin.css'
import '@vkontakte/vkui/dist/vkui.css';
import axios from "axios";
import './admin/styleHomeAdmin.css'
import {Avatar} from '@vkontakte/vkui';
import {RankPost} from "./RankPost";
import './admin/styleConfirmAdmin.css';
import {Foot} from "./Foot";
import Arrow from "../img/arrow.svg";

class Rank extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            DataBaseRank: []
        };
    }
    componentDidMount() {
        var self = this;

        axios.get(this.props.urlApp + 'rankAppBack.php', {
        }).then(function (response) {
            if( response.data) {
                self.setState({
                    DataBaseRank: response.data
                });
            }

        });
    }
    TablePush = () => {
        this.props.history.push('/leagueselection');
    }
    OnlinePush = () => {

        this.props.history.push('/Match');
    }
    NewsPush = () => {
        if (this.props.checkAd) {
            this.props.history.push('/');
        } else {
            this.props.history.push('/Admin');
        }
    }
    RankLink = () => {

        if(document.getElementById('RankLink1').scrollTop!==null)
        {
            var top = Math.max(document.getElementById('RankLink1').scrollTop );
            if(top > 0) {

                document.getElementById('RankLink1').scrollTop = document.getElementById('RankLink1').scrollTop - 100;
                var t= setTimeout(
                    function () {
                        if(document.getElementById('RankLink1').scrollTop!==null) {
                            this.RankLink();
                        }
                    }.bind(this),
                    15
                );
            }}
    }



    render() {
        return (
            <div className="wrapperHomeG" style={{gridAutoRows: this.props.hIOS}}>

                <div className="wrapperTableParse" id="RankLink1">
                    <div className="headerTab" style={{backgroundImage: `url(${this.props.backImg})`}}>

                        <div className="HeaderTextComments1"><div>Рейтинг</div></div>
                    </div>
                        <RankPost colorApp={this.props.colorApp} DataBaseRank={this.state.DataBaseRank}/>
                </div>

                <Foot RankLink={this.RankLink} NewsPush={this.NewsPush} color1={this.props.colorNoActive} color2={this.props.colorNoActive} color3={this.props.colorApp} color4={this.props.colorNoActive} TablePush={this.TablePush} OnlinePush={this.OnlinePush}/>
            </div>
        );
    }
}

export default Rank;

