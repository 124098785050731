import {Avatar} from '@vkontakte/vkui';
import React from "react";


const ElementsRank = (props) => {
    return <div style={{background: `${props.colorBack}`}} className="rankBlock">
        <Avatar className="photoRank" size={55} src={props.photoAva}/>
        <div className="RankName">
            <div style={{color: `${props.colorText}`}} className="RankNameFirst" style={{color: `${props.colorText}`}}>{props.firstName} {props.lastName}</div>
            <div style={{color: `${props.colorRank}`}} className="RankNameRank">{props.rank}</div>

        </div>
        <div className="numRank">
            <div className="idRunk" style={{color: `${props.colorText}`}}>{props.id} место</div>
            <div className="numRankS">
            <div className="numRunkTe" style={{color: `${props.colorText}`}}>  {props.numRank}</div>
            <div className="starRank">
            <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.2402 4.17783L7.80098 3.99379C7.75004 3.98951 7.7052 3.95741 7.68686 3.90606L6.75763 1.53071C6.62925 1.2033 6.33988 1 6.00161 1C5.66334 1 5.37601 1.2033 5.24559 1.53285L4.31432 3.91034C4.29394 3.95955 4.25115 3.99379 4.2002 3.99807L1.76504 4.18211C1.43492 4.20137 1.1537 4.4282 1.0457 4.76204C0.93566 5.10015 1.02736 5.45538 1.2882 5.68864L3.17316 7.32356C3.21187 7.3578 3.23021 7.41344 3.21799 7.4648L2.64333 9.9343C2.57608 10.1911 2.62295 10.4565 2.77171 10.664C2.92454 10.8737 3.16297 11 3.41361 11C3.56849 11 3.71928 10.9508 3.84359 10.863L5.9038 9.51701C5.94659 9.48919 6.00161 9.48919 6.0444 9.51701L8.12499 10.8588C8.25541 10.9508 8.4062 11 8.55904 11C8.79339 11 9.02569 10.8823 9.17649 10.6833C9.3334 10.4779 9.39046 10.2104 9.3334 9.94286L8.7567 7.46694C8.74448 7.41344 8.76282 7.3578 8.80154 7.32356L10.715 5.68436C10.9718 5.45324 11.0635 5.09801 10.9555 4.7599C10.8495 4.42606 10.5663 4.19709 10.2402 4.17783ZM10.3971 5.27991L8.48772 6.91697C8.30024 7.07747 8.21465 7.34282 8.27375 7.59105L8.85044 10.0627C8.88101 10.2082 8.82599 10.3088 8.78931 10.3559C8.73225 10.4308 8.64666 10.4779 8.56108 10.4779C8.50809 10.4779 8.45511 10.4607 8.39805 10.4201L6.30932 9.0719C6.20947 9.0077 6.09535 8.97346 5.97716 8.97346C5.85896 8.97346 5.74281 9.0077 5.64296 9.07404L3.5746 10.4244C3.44214 10.5164 3.26486 10.4757 3.17112 10.3473C3.1324 10.2938 3.09164 10.2018 3.12832 10.067L3.70502 7.58892C3.76208 7.34282 3.67853 7.07961 3.49309 6.91911L1.61221 5.28633C1.46345 5.15151 1.5042 4.97817 1.52051 4.92895C1.53681 4.87973 1.60406 4.71496 1.79968 4.70426L4.23892 4.52022C4.47938 4.50096 4.68723 4.34475 4.77894 4.10935L5.71021 1.73186C5.7856 1.53927 5.95474 1.52429 6.00365 1.52429C6.05256 1.52429 6.22169 1.53927 6.29709 1.73186L7.22836 4.10935C7.32006 4.34475 7.52792 4.50096 7.76838 4.52022L10.2117 4.7064C10.4032 4.7171 10.4705 4.88187 10.4868 4.93109C10.499 4.97603 10.5398 5.14937 10.3971 5.27991Z" fill={props.colorStar} stroke={props.colorStar}/>
            </svg>
            </div>
        </div>
        </div>
    </div>

}

export const RankPost = (props) => {
    let DataRank = props.DataBaseRank.map((el) => <ElementsRank
        rank={el.rank}
        photoAva={el.photoAva}
        numRank={el.numRank}
        id={el.id}
        firstName={el.firstName}
        lastName={el.lastName}
        colorApp={props.colorApp}
        colorText={el.colorText}
        colorBack={el.colorBack}
        colorStar={el.colorStar}
        colorRank={el.colorRank}

    />)
    return (
        <div  style={{height: "calc(100% + 1px)", backgroundColor: "#eeeeee"}}>
            {DataRank}
        </div>
    );

}