import React from "react";
import './styleTuningAdmin.css'
import '@vkontakte/vkui/dist/vkui.css';
import axios from "axios";
import Arrow from "../../img/arrow.svg";
import {Avatar} from '@vkontakte/vkui';
import {confirmAlert} from 'react-confirm-alert';
import './styleConfirmAdmin.css';

class TuningAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileLoaded: 0,
            checkload: true,
            uploadResult: "null",
            colorApp: "#eeeeee"
        };
    }

    componentDidMount() {
        var self = this;
        axios.get(this.props.urlApp + 'infoAppBack.php', {}).then(function (response) {
            self.setState({
                backImg: response.data.headerImage,
                headerName: response.data.headerName,
                headerDescription: response.data.headerDescription,
                colorApp: response.data.color
            });
        });
    }

    backPost = () => {
        this.props.history.push('/Admin');
    }

    HexTest=()=>{
        this.setState({colorApp: "#" + document.getElementById('hexTest').value})
    }
    NameTest=()=>{
        this.setState({headerName: document.getElementById('nameTest').value})
    }
    DescriptionTest=()=>{
        this.setState({headerDescription: document.getElementById('descriptionTest').value})
    }

    PrewieImg = () => {
        var self = this;
        const data = new FormData();
        data.append('file', this.state.selectedFile, this.state.selectedFile.name);
        data.append('key', this.props.uniqueAdminKey);
        data.append('vk_id', this.props.fetchedUser.id);
        data.append('urlApp', this.props.urlApp);
        axios.post( this.props.urlApp + 'imgPrewieAppBack.php', data, {
            onUploadProgress: ProgressEvent => {
                this.setState({
                    fileLoaded: (ProgressEvent.loaded / ProgressEvent.total*100),
                })
            },
        }).then(function (response) {
            self.setState({
                backImg: response.data.headerImage
            });
        });

    }

    handleselectedFile = event => {
//берем имя файла
        var filename = document.getElementById("input-file1").files;
        filename = filename[0]['name'];
        if(filename.length > 15){
            filename = filename.substring(0, 15) + '...';
        }
        this.setState({
            selectedFile: event.target.files[0],
            filename: filename,

        });
        setTimeout(
            function () {
                document.getElementById('loadImgR').innerHTML="Загружено";
            }.bind(this),
            1000);

    }
    CheckSaveInfoApp=()=>{
        confirmAlert({
            title: 'Новый пост',
            message: 'Вы уверенны что заполнили все поля и загрузили изображение?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => {
                      this.SaveInfoApp();
                    }
                },
                {
                    label: 'Нет',
                }
            ]
        });
    }
    SaveInfoApp=()=>{
        var self = this;
        const data = new FormData();
        data.append('headerName', this.state.headerName);
        data.append('headerDescription', this.state.headerDescription);
        data.append('headerImage', this.state.backImg);
        data.append('colorApp', this.state.colorApp);
        data.append('key', this.props.uniqueAdminKey);
        data.append('vk_id', this.props.fetchedUser.id);
        axios.post( this.props.urlApp + 'changeInfoApp.php', data, {

        });
        setTimeout(
            function () {
                this.props.infoAppref();
            }.bind(this),
            2500);
    }

//клик на нарисованный <input type="file" />
    clickToFile = () => {
        var file = document.querySelector('#input-file1');
        file.click();

    }

    render() {
        return (
            <div className="wrapperTuningAdmin" >
                <div className="headerTuningAd" style={{backgroundImage: `url(${this.state.backImg})`}}>
                    <div className="headerTuningAdmin">{this.state.headerName}</div>
                    <div className="headerDescriptionTuningAdmin">{this.state.headerDescription}</div>
                    <div onClick={this.backPost} className="tuningAdmin"><img src={Arrow}/></div>
                </div>
                <div className="testTuning">
                    <div className="checkLike">
                        <svg width="17" height="16"
                             viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.449 2.76284C9.13512 1.13662 10.7025 0.000339975 12.5256 0.000339975C14.9814 0.000339975 16.7501 2.1012 16.9725 4.60496C16.9725 4.60496 17.0925 5.22648 16.8283 6.34542C16.4686 7.8693 15.623 9.22318 14.483 10.2564L8.449 15.64L2.51668 10.2561C1.37666 9.22318 0.531081 7.86896 0.171361 6.34508C-0.0928193 5.22614 0.0272007 4.60462 0.0272007 4.60462C0.249561 2.10086 2.01824 0 4.47406 0C6.29748 0 7.76288 1.13662 8.449 2.76284Z"
                                fill={this.state.colorApp}/>
                        </svg>
                    </div>
                    <div className="rubricCheck">
                        <div style={{color: `${(this.state.colorApp)}`}}>Новое Популярное</div>
                        <div style={{background: `${(this.state.colorApp)}`}}></div>
                    </div>
                    <div className="repost-cont">
                        <div style={{background: `${(this.state.colorApp)}`}}>
                            Поделиться
                        </div>
                    </div>
                </div>
                <div className="tuningAdminBody">
                    <div className="bodyTuningUr">
                        <div className="bodyTuningText">Цвет hex #</div>
                        <textarea id="hexTest" className="tuningAdminInput"/>
                        <div onClick={this.HexTest} className="viewTuningAdmin">✓</div>
                    </div>
                    <div className="bodyTuningUr">
                        <div className="bodyTuningText">Шапка</div>
                        <div onClick={this.clickToFile} className="loadTunBut" >
                            <div id="loadImgR" className="loadPostTextBut12">Загрузить</div>
                            <input type="file" onChange={this.handleselectedFile} style={{display: 'none', width: "0 px" }}
                                   id="input-file1" accept="image/*"/>
                        </div>
                        <div onClick={this.PrewieImg} className="viewTuningAdmin">✓</div>
                    </div>
                    <div className="bodyTuningUr">
                        <div className="bodyTuningText">Название</div>
                        <textarea id="nameTest" className="tuningAdminInput"/>
                        <div onClick={this.NameTest} className="viewTuningAdmin">✓</div>
                    </div>
                    <div className="bodyTuningUr">
                        <div className="bodyTuningText">Описание</div>
                        <textarea id="descriptionTest" className="tuningAdminInput"/>
                        <div onClick={this.DescriptionTest} className="viewTuningAdmin">✓</div>
                    </div>
                    <div onClick={this.CheckSaveInfoApp} className="SaveTunAdmin">Сохранить изменения</div>
                </div>
            </div>
        );
    }
}

export default TuningAdmin;

