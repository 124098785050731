import React from "react";
import '@vkontakte/vkui/dist/vkui.css';
import axios from "axios";
import Arrow from "../img/arrow.svg";
import './admin/styleOnlineAdmin.css';
import {Foot} from "./Foot";

class Match extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            Table: "",
            switchHead: true,
        };
    }

    componentDidMount() {
        var self = this;


        axios.get(this.props.urlApp + 'onlineParse.php', {
            params: {
                Today: 123
            }
        }).then(function (response) {
            self.setState({
                Table: response.data.Table
            });
        });

        this.timer = setInterval(this.refresh, 60000);

    }

    refresh = () => {

        var self = this;
        if (!this.state.switchHead) {
            axios.get(this.props.urlApp + 'onlineParse.php', {
                params: {
                    Today: 321
                }
            }).then(function (response) {
                self.setState({
                    Table: response.data.Table
                });
            });
        } else {

            axios.get(this.props.urlApp + 'onlineParse.php', {
                params: {
                    Today: 123
                }
            }).then(function (response) {
                self.setState({
                    Table: response.data.Table
                });
            });
        }

    }

    headingSwitchToday = () => {
        if (!this.state.switchHead) {
            document.getElementById('colorNew').style.color = this.props.colorApp;
            document.getElementById('colorNewLine').style.backgroundColor = this.props.colorApp;
            document.getElementById('colorPopularLine').style.backgroundColor = "#C4CCE0";
            document.getElementById('colorPopular').style.color = "#3E3E3E";
            this.setState({switchHead: !this.state.switchHead});
            var self = this;
            axios.get(this.props.urlApp + 'onlineParse.php', {
                params: {
                    Today: 123
                }
            }).then(function (response) {
                self.setState({
                    Table: response.data.Table
                });
            });
        }
    }

    headingSwitchTomorrow = () => {
        if (this.state.switchHead) {
            document.getElementById('colorNew').style.color = "#3E3E3E";
            document.getElementById('colorNewLine').style.backgroundColor = "#C4CCE0";
            document.getElementById('colorPopularLine').style.backgroundColor = this.props.colorApp;
            document.getElementById('colorPopular').style.color = this.props.colorApp;
            this.setState({switchHead: !this.state.switchHead});
            var self = this;
            axios.get(this.props.urlApp + 'onlineParse.php', {
                params: {
                    Today: 321
                }
            }).then(function (response) {
                self.setState({
                    Table: response.data.Table
                });
            });
        }
    }
    TablePush = () => {

        this.props.history.push('/leagueselection');
    }
    OnlinePush = () => {
        if(document.getElementById('matchParse1').scrollTop!==null)
        {
            var top = Math.max(document.getElementById('matchParse1').scrollTop );
            if(top > 0) {

                document.getElementById('matchParse1').scrollTop = document.getElementById('matchParse1').scrollTop - 100;
                var t= setTimeout(
                    function () {
                        if(document.getElementById('matchParse1').scrollTop!==null) {
                            this.OnlinePush();
                        }
                    }.bind(this),
                    15
                );
            }}
    }
    NewsPush = () => {
        if (this.props.checkAd) {
            this.props.history.push('/');
        } else {
            this.props.history.push('/Admin');
        }
    }
    RankLink = () => {

        this.props.history.push('/rank');
    }


    render() {
        return (

            <div className="wrapperHomeG" style={{gridAutoRows: this.props.hIOS}}>
                <div className="wrapperOnlineParse" id="matchParse1">
                    <div className="headerMatch" style={{backgroundImage: `url(${this.props.backImg})`}}>

                        <div className="HeaderTextComments1"><div>Матчи</div></div>
                    </div>
                    <div className="headingSwitch1">
                        <div onClick={this.headingSwitchToday} className="headingSwitchSeparation">
                            <div id="colorNew" style={{color: "#89C42F"}} className="headingSwitchNew">Сегодня
                            </div>
                            <div id="colorNewLine" style={{backgroundColor: this.props.colorApp}}
                                 className="headingSwitchNewHr"></div>
                        </div>
                        <div onClick={this.headingSwitchTomorrow} className="headingSwitchSeparation">
                            <div id="colorPopular" style={{color: "#3E3E3E"}}
                                 className="headingSwitchPopular">Завтра
                            </div>
                            <div id="colorPopularLine" style={{backgroundColor: "#C4CCE0"}}
                                 className="headingSwitchPopularHr"></div>
                        </div>
                    </div>
                    <div className="matchParse"  dangerouslySetInnerHTML={{__html: this.state.Table}}>
                    </div>

                </div>
                <Foot RankLink={this.RankLink} NewsPush={this.NewsPush} color1={this.props.colorApp} color2={this.props.colorNoActive} color3={this.props.colorNoActive} color4={this.props.colorNoActive} TablePush={this.TablePush} OnlinePush={this.OnlinePush}/>
            </div>
        );
    }
}

export default Match;

