import React from "react";
import ViewsImg from "../img/views.svg";
import CommentsImg from "../img/comments.svg";

const ElementsPost = (props) => {
    return <div className="postHome">
        <div className="ImgPost">
            <img src={props.imghead}></img>
        </div>
        <div className="postMessage">
            <div className="postTitle">{props.postTitle}</div>

            <div className="postDescription" dangerouslySetInnerHTML={{__html: props.postDescription}}>

            </div>

        </div>

        <div className="like-cont">
            <div className="cont">
                <img className="cont-img" src={ViewsImg}></img>
                <div className="cont-div">{props.viewPost}</div>

            </div>
            <div onClick={props.functionComments1.bind(null, props.idPost, props.idmassiv)} className="cont" >
                <img className="cont-img"
                     src={CommentsImg}></img>
                <div className="cont-div">{props.CommentCount}</div>

            </div>
            <div onClick={props.functionLike.bind(null, props.idPost, props.idmassiv)} className="cont">
                <div className="cont-img">
                    <svg width="17" height="16"
                         viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.449 2.76284C9.13512 1.13662 10.7025 0.000339975 12.5256 0.000339975C14.9814 0.000339975 16.7501 2.1012 16.9725 4.60496C16.9725 4.60496 17.0925 5.22648 16.8283 6.34542C16.4686 7.8693 15.623 9.22318 14.483 10.2564L8.449 15.64L2.51668 10.2561C1.37666 9.22318 0.531081 7.86896 0.171361 6.34508C-0.0928193 5.22614 0.0272007 4.60462 0.0272007 4.60462C0.249561 2.10086 2.01824 0 4.47406 0C6.29748 0 7.76288 1.13662 8.449 2.76284Z"
                            fill={props.color}/>
                    </svg>
                </div>
                <div className="cont-div" style={{color: `${(props.color)}`}}>{props.likePost}</div>

            </div>
            <div className="repost-cont">
                <div onClick={props.repostFun.bind(null, props.idmassiv)} style={{background: `${(props.colorApp)}`}}>Поделиться</div>
            </div>
        </div>
    </div>
}
export const Post = (props) => {


    let DataBasePostElements = props.DataBasePost.map((el, index) => <ElementsPost
        colorApp={props.colorApp} idmassiv={index}
        functionComments1={props.functionComments1}
        functionLike={props.functionLike}
        idPost={el.id} color={el.color}
        CommentCount={el.CommentNum}
        viewPost={el.ViewPost} likePost={el.LikePost}
        imghead={el.imghead} postTitle={el.postTitle}
        postDescription={el.postDescription}
        repostFun={props.repostFun}/>)

    return <div className="postsBlock">
        {DataBasePostElements}
        <div style={{height: "5px"}}></div>
    </div>
}